import { makeAutoObservable, toJS } from 'mobx';

class RequestStore {
    private _fileList자료PDF: any[] = [];
    private _fileList사진자료: any[] = [];
    private _fileList명함: any[] = [];
    private _fileList기타: any[] = [];

    spinning = false;

    constructor() {
        makeAutoObservable(this);
    }

    // 파일 리스트 가져오는 getter
    get fileList자료PDF(): any[] {
        return this._fileList자료PDF;
    }

    get fileList사진자료(): any[] {
        return this._fileList사진자료;
    }

    get fileList명함(): any[] {
        return this._fileList명함;
    }

    get fileList기타(): any[] {
        return this._fileList기타;
    }

    // 파일 리스트 설정하는 setter (액션 메서드로 변경)
    setFileList자료PDF(value: any[]) {
        this._fileList자료PDF = value;
    }

    setFileList사진자료(value: any[]) {
        this._fileList사진자료 = value;
    }

    setFileList명함(value: any[]) {
        this._fileList명함 = value;
    }

    setFileList기타(value: any[]) {
        this._fileList기타 = value;
    }

    // 스피너 설정 메서드
    setSpinning(spinning: boolean) {
        this.spinning = spinning;
    }

    // 모든 파일 데이터를 가져오는 메서드
    get allFiles() {
        return {
            자료PDF: toJS(this._fileList자료PDF),
            사진자료: toJS(this._fileList사진자료),
            명함: toJS(this._fileList명함),
            기타: toJS(this._fileList기타)
        };
    }
}

const store = new RequestStore();
export { store };