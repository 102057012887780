import React, { useState } from 'react';
import { Form, Button, Steps, Spin, notification, Card, Divider, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import EssentialFormItems from '../components/requestform/EssentialFormItems';
import AdditionalFormItems from '../components/requestform/AdditionalFormItems';
import { store } from '../stores/RequestStore'; // Store import
import testGif from '../assets/thumbnail.png'; // 이미지 경로를 실제 경로로 교체
import {
    Container,
    FormWrapper,
    StyledColumn,
    DividerColumn,
    CustomButton,
} from '../components/requestform/StyledComponents';
import axios from "axios";
import {toJS} from "mobx";
import PrivacyConsentComponent from "../components/requestform/PrivacyConsentComponent"; // styled-components import

const { Step } = Steps;

const RequestForm: React.FC = () => {
    const [form] = Form.useForm();
    const [currentStep, setCurrentStep] = useState(0);
    const [isPrivacyChecked, setIsPrivacyChecked] = useState(false); // 개인정보 동의 여부

    const onPrivacyChange = (checked: boolean) => {
        setIsPrivacyChecked(checked);
    };

    // 스텝 이동 시 필수 항목 체크
    const onNext = async () => {
        try {
            // 현재 스텝의 필수 항목 유효성 검사
            await form.validateFields();
            // 유효성 검사를 통과하면 다음 스텝으로 이동
            setCurrentStep((prevStep) => prevStep + 1);
        } catch (error) {
            // 유효성 검사 실패 시 경고 메시지 표시
            notification.error({
                message: '필수 항목을 입력해주세요.',
                placement: 'top',
                duration: 2,
            });
        }
    };

    const onFinish = async (values: any) => {
        if (!isPrivacyChecked) {
            notification.error({
                message: '개인정보 동의가 필요합니다.',
                placement: 'top',
                duration: 2,
            });
            return;
        }

        try {
            // 데이터 준비
            let fieldsValue = form.getFieldsValue(true);

            // careerDetails를 단순한 문자열 배열로 변환
            const transformedCareerDetails = (fieldsValue.careerDetails || []).map((item: string) => item);

            // 변환된 데이터를 포함한 최종 데이터 구조 만들기
            const finalData = {
                ...fieldsValue,
                career: [
                    ...fieldsValue.career, // 기존의 첫 번째, 두 번째 필드 값
                    ...transformedCareerDetails, // 추가된 경력 사항을 포함
                ],
            };

            let s = JSON.stringify(finalData);
            console.log(s);

            let data = new FormData();
            data.append("form_data", s);
            toJS(store.fileList자료PDF).forEach((value) => {
                data.append('fileList자료PDF', value.originFileObj);
            });
            toJS(store.fileList사진자료).forEach((value) => {
                data.append('fileList사진자료', value.originFileObj);
            });
            toJS(store.fileList명함).forEach((value) => {
                data.append('fileList명함', value.originFileObj);
            });
            toJS(store.fileList기타).forEach((value) => {
                data.append('fileList기타', value.originFileObj);
            });

            store.spinning = true;

            const response = await axios.post('/api/upload', data, {});
            console.log(response);
            if (response.status === 200) {
                notification.success({
                    message: '제출이 완료되었습니다.',
                    placement: 'top',
                    duration: 2,
                });
            }
        } catch (error) {
            notification.error({
                message: '제출 중 오류가 발생했습니다.',
                placement: 'top',
                duration: 2,
            });
        } finally {
            store.spinning = false;
        }
    };

    const steps = [
        {
            title: '필수항목',
            content: <EssentialFormItems form={form} />,
        },
        {
            title: '추가항목',
            content: <AdditionalFormItems form={form} />,
        },
        {
            title: '개인정보 수집 동의',
            content: (
                <PrivacyConsentComponent onConsentChange={onPrivacyChange} />
            ),
        },
    ];

    return (
        <Container>
            <FormWrapper>
                <Row gutter={16} justify="center">
                    {/* 왼쪽 이미지 */}
                    <StyledColumn xs={24} md={10}>
                        <Card bordered={false} style={{ border: 'none', boxShadow: 'none' }}>
                            <img src={testGif} alt="미리보기 이미지" style={{ width: '100%' }} />
                        </Card>
                    </StyledColumn>

                    {/* 가운데 Divider */}
                    <DividerColumn xs={0} md={1}>
                        <Divider type="vertical" style={{ height: '100%' }} />
                    </DividerColumn>

                    {/* 오른쪽 Steps + 폼 */}
                    <StyledColumn xs={24} md={13}>
                        <Steps current={currentStep}>
                            {steps.map((step, index) => (
                                <Step key={index} title={step.title} />
                            ))}
                        </Steps>
                        <Form form={form} layout="vertical" onFinish={onFinish} style={{ marginTop: '20px' }}>
                            {steps[currentStep].content}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: currentStep > 0 ? 'space-between' : 'flex-end',
                                    gap: '10px',
                                    marginTop: '20px',
                                }}
                            >
                                {currentStep > 0 && (
                                    <Button
                                        onClick={() => setCurrentStep(currentStep - 1)}
                                        style={{ width: '150px' }}
                                    >
                                        이전
                                    </Button>
                                )}
                                {currentStep < steps.length - 1 ? (
                                    <Button
                                        type="primary"
                                        onClick={onNext}
                                        style={{ width: '150px' }}
                                    >
                                        다음
                                    </Button>
                                ) : (
                                    <CustomButton
                                        type="primary"
                                        htmlType="submit"
                                        style={{ width: '150px' }}
                                    >
                                        제출하기
                                    </CustomButton>
                                )}
                            </div>
                        </Form>
                    </StyledColumn>
                </Row>
            </FormWrapper>
            <Spin spinning={store.spinning} />
        </Container>
    );
};

export default observer(RequestForm);