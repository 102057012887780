import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Card, Col, Layout, Row, Table, Typography, Divider } from "antd";
import { Pie, Column } from "@antv/g2plot";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { 프로필이벤트Store } from "../../stores/mediprofilelogStore";

const { Title } = Typography;
const { Content } = Layout;

// Styled Components
const StyledCard = styled(Card)`
    margin-bottom: 16px;
    width:100%;
`;

const StyledChartContainer = styled.div`
  width: 90%; /* Card의 너비에 맞춤 */
  height: 250px; /* 고정 높이 */
  display: flex; /* 차트를 중앙에 정렬 */
  align-items: center;
  justify-content: center;
`;

const StyledLayout = styled(Layout)`
    padding: 24px;
`;

const StyledRow = styled(Row)`
    margin-top: 16px;
`;

export interface ProfileEvent {
    id: number;
    createdDate: string;
    uid: string;
    idNumber: string;
    eventType: string;
    eventDetail: string;
    name: string;
}

const Dashboard: React.FC = observer(() => {
    const [dataSource, setDataSource] = useState<ProfileEvent[]>([]);
    const [nameData, setNameData] = useState<{ name: string; value: number }[]>([]);
    const [eventTypeData, setEventTypeData] = useState<{ type: string; value: number }[]>([]);

    useEffect(() => {
        const loadData = async () => {
            await 프로필이벤트Store.loadProfileEvents();
            const eventsData = 프로필이벤트Store.profileEventsData;
            setDataSource(eventsData);

            // 이름별 데이터 집계
            const nameCount = eventsData.reduce((acc: { [key: string]: number }, event) => {
                const name = event.name || "이름 없음";
                acc[name] = (acc[name] || 0) + 1;
                return acc;
            }, {});
            setNameData(Object.entries(nameCount).map(([name, count]) => ({ name, value: count })));

            // 이벤트 타입별 데이터 집계
            const eventTypeCount = eventsData.reduce((acc: { [key: string]: number }, event) => {
                const type = event.eventType || "타입 없음";
                acc[type] = (acc[type] || 0) + 1;
                return acc;
            }, {});
            setEventTypeData(Object.entries(eventTypeCount).map(([type, count]) => ({ type, value: count })));
        };

        loadData();
    }, []);

    useEffect(() => {
        // 이름별 분포 차트 생성
        if (nameData.length > 0) {
            const columnPlot = new Column('name-chart-container', {
                data: nameData,
                xField: "name",
                yField: "value",
                color: "#5B8FF9",
                autoFit: true,
                xAxis: {
                    title: { text: "이름", style: { fontSize: 14, fontWeight: "bold" } },
                    label: {
                        rotate: 0, // 레이블 기울기
                        style: { fontSize: 12 },
                        offset: 30, // 축과 레이블 간 간격
                    },
                },
                yAxis: {
                    title: { text: "이름별 개수", style: { fontSize: 14, fontWeight: "bold" } },
                },
                label: {
                    position: "middle",
                    style: { fill: "#000", opacity: 0.6 },
                },
                tooltip: {
                    formatter: (datum) => ({ name: "이름별 개수", value: datum.value }),
                },
            });
            columnPlot.render();
        }

        // 이벤트 타입별 분포 차트 생성
        if (eventTypeData.length > 0) {
            const piePlot = new Pie('event-type-chart-container', {
                appendPadding: 10,
                data: eventTypeData,
                angleField: "value",
                colorField: "type",
                radius: 0.8,
                legend: {
                    position: "bottom",
                },
                label: {
                    type: "outer",
                    content: "{name}: {percentage}",
                },
                interactions: [{ type: "element-active" }],
                tooltip: {
                    formatter: (datum) => ({ name: "이벤트 타입별 개수", value: datum.value }),
                },
            });
            piePlot.render();
        }
    }, [nameData, eventTypeData]);

    const columns = [
        { title: "순번", dataIndex: "id", render: (_: any, __: any, index: any) => index + 1 },
        { title: "생성 날짜", dataIndex: "createdDate", render: (text: any) => dayjs(text).format("YYYY-MM-DD HH:mm:ss") },
        { title: "이름", dataIndex: "name" },
        { title: "이벤트 타입", dataIndex: "eventType" },
        { title: "이벤트 세부 정보", dataIndex: "eventDetail" },
    ];

    return (
        <StyledLayout>
            <Content>
                <Title level={2}>Profile Events Dashboard</Title>

                <StyledRow gutter={16}>
                    <Col span={16}>
                        <StyledCard title="Profile Events 목록">
                            <Table columns={columns} dataSource={dataSource} rowKey="id" pagination={{ pageSize: 10 }} />
                        </StyledCard>
                    </Col>
                    <Col span={8}>
                        <StyledCard title="이름별 분포">
                            <StyledChartContainer id="name-chart-container" />
                        </StyledCard>
                        <StyledCard title="이벤트 타입별 분포">
                            <StyledChartContainer id="event-type-chart-container" />
                        </StyledCard>
                    </Col>
                </StyledRow>
            </Content>
        </StyledLayout>
    );
});

export default Dashboard;