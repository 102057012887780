import React from 'react';
import { Form, Input, Upload, Button } from 'antd';
import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import { observer } from 'mobx-react';
import { store } from '../../stores/RequestStore'

const AdditionalFormItems: React.FC<{ form: any }> = observer(({ form }) => {
    const handleUploadChange = (info: any, fileListSetter: (fileList: any[]) => void) => {
        let fileList = [...info.fileList];
        fileList = fileList.map((file) => {
            if (file.response) {
                file.status = 'done';
            }
            return file;
        });
        fileListSetter(fileList);
    };

    const normFile = (e: any) => {
        if (Array.isArray(e)) return e;
        return e?.fileList;
    };

    return (
        <>
            {/* 소속 리스트 */}
            <Form.List name="profile" initialValue={['']}>
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item label="소속" required={false} key={field.key}>
                                <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    noStyle
                                >
                                    <Input
                                        placeholder="ex) 기관/직책(메디펀/대표이사)"
                                        style={{ width: '100%' }}
                                        addonAfter={
                                            index > 0 ? (
                                                <Button type="link" onClick={() => remove(field.name)}>
                                                    삭제
                                                </Button>
                                            ) : null
                                        }
                                    />
                                </Form.Item>
                            </Form.Item>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: '100%' }}
                                icon={<PlusOutlined />}
                            >
                                대표 프로필 추가
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>



            {/* 기타 자료 업로드 */}
            <Form.Item label="기타 자료">
                <Upload
                    listType="picture"
                    maxCount={10}
                    onChange={(info) =>
                        handleUploadChange(info, (fileList) => store.setFileList기타(fileList)) // setter 메서드 호출
                    }
                >
                    <Button icon={<UploadOutlined />}>업로드</Button>
                </Upload>
                <div style={{ paddingTop: '5px', fontSize: '12px' }}>
                    ex) 회사자료, 개인자료, pdf, png 등
                </div>
            </Form.Item>

            {/* SNS 리스트 */}
            <Form.List name="sns" initialValue={['']}>
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item label={index === 0 ? 'SNS' : ''} required={false} key={field.key}>
                                <Form.Item {...field} validateTrigger={['onChange', 'onBlur']} noStyle>
                                    <Input
                                        placeholder="ex) https://"
                                        style={{ width: '100%' }}
                                        addonAfter={
                                            index > 0 ? (
                                                <Button type="link" onClick={() => remove(field.name)}>
                                                    삭제
                                                </Button>
                                            ) : null
                                        }
                                    />
                                </Form.Item>
                            </Form.Item>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: '100%' }}
                                icon={<PlusOutlined />}
                            >
                                SNS 추가
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>

            {/* 동영상 링크 리스트 */}
            <Form.List name="link" initialValue={['']}>
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item label={index === 0 ? '동영상 링크주소' : ''} required={false} key={field.key}>
                                <Form.Item {...field} validateTrigger={['onChange', 'onBlur']} noStyle>
                                    <Input
                                        placeholder="ex) https://"
                                        style={{ width: '100%' }}
                                        addonAfter={
                                            index > 0 ? (
                                                <Button type="link" onClick={() => remove(field.name)}>
                                                    삭제
                                                </Button>
                                            ) : null
                                        }
                                    />
                                </Form.Item>
                            </Form.Item>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: '100%' }}
                                icon={<PlusOutlined />}
                            >
                                동영상 링크 추가
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <Form.Item name="recommender" label="추천인">
                <Input placeholder="ex) 메디펀" />
            </Form.Item>
        </>
    );
});

export default AdditionalFormItems;