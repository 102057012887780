import { Button, Form, FormInstance, Input, Space } from 'antd';
import React, { Component } from 'react';
import { NavigateFunction } from "react-router/dist/lib/hooks";
import { withRouter } from "../common/UtilCompo";
import { WrapMessageAPI } from "../common/HocMessage";
import styled from "styled-components";
import { apollo_client } from "../common/ApolloHelpper";
import { gql } from "@apollo/client";
import blockLogo from '../../assets/block_logo.png';


const Warapper = styled.div`
    min-height: 80vh;
    position: relative;
    background-color: #f0f2f5; /* 배경 색 추가 */
    display: flex;
    flex-direction: column;
    width: 100vw;
`;

const WhiteBox = styled.div`
    display: flex;
    justify-content: center;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.025);
    padding: 1.5rem;
    width: 360px;
    background: white;
    border-radius: 10px;
    align-items: center;
`;

const FormLayout = styled.div`
    padding: 0 3rem 3rem 3rem;
`;

const Contents = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
`;

const Footer = styled.div`
    width: 100%;
    background-color: #000000;
    position: absolute;
    bottom: 0;
    left: 0;
`;

const FooterContents = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    padding: 0.5em; /* 패딩 조정 */
`;

const Address = styled.div`
    color: #ffffff;
    text-align: center;
    font-size: 0.8em; /* 글자 크기 조정 */
`;

const LogoImage = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto 1em auto;
`;

class Login extends Component<{ navigate: NavigateFunction } & any, any> {
    login = async (username: string, password: string) => {
        try {
            let promise = await apollo_client.query({
                query: gql`
                    query login($username: String!, $password: String!) {
                        login(username: $username, password: $password) {
                            credentials
                        }
                    }
                `,
                variables: { username, password },
                fetchPolicy: "no-cache",
            });

            let token = promise.data.login.credentials;
            // sessionStorage에 저장 후 storage 이벤트 발생시키기
            if (token) {
                sessionStorage.setItem('auth_token', token);
                window.dispatchEvent(new Event("storage")); // storage 이벤트 발생
                this.props.setAuth(true);
                this.props.messageApi?.success("로그인되었습니다.");

                const redirectPath = this.props.location.state?.from || "/admin";
                this.props.navigate(redirectPath, { replace: true });
            } else {
                this.props.messageApi?.error("로그인에 실패했습니다.");
            }
        } catch (e) {
            console.error("로그인 오류:", e);
            this.props.messageApi?.error("로그인에 실패했습니다.");
        }
    };

    form!: FormInstance;

    render() {
        return (
            <Warapper>
                <Contents>
                    <WhiteBox>
                        <FormLayout>
                            <LogoImage src={blockLogo} alt="Logo" />
                            <Form ref={(ref: FormInstance) => (this.form = ref)}>
                                <Form.Item name={"id"}>
                                    <Input placeholder={"ID"} />
                                </Form.Item>
                                <Form.Item name={"password"}>
                                    <Input
                                        placeholder={"Password"}
                                        type={"password"}
                                        onPressEnter={() => {
                                            let fieldsValue = this.form.getFieldsValue();
                                            this.login(fieldsValue.id, fieldsValue.password);
                                        }}
                                    />
                                </Form.Item>
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    <Button
                                        style={{ width: '100%', background: "black" }}
                                        type="primary"
                                        onClick={() => {
                                            let fieldsValue = this.form.getFieldsValue();
                                            this.login(fieldsValue.id, fieldsValue.password);
                                        }}
                                    >
                                        login
                                    </Button>
                                </Space>
                            </Form>
                        </FormLayout>
                    </WhiteBox>
                </Contents>
                <Footer>
                    <FooterContents>
                        <Address>
                            +82. 507.1390 .9493 hello@medifun.co.kr<br/>
                            [BITC] 부산광역시 남구 문현금융로 40 BIFC 8층<br/>
                            [MITC] 부산광역시 남구 신선로 428, 8호관 327<br/>
                            © by MediNomi. Powered and secured by MEDIFUN
                        </Address>
                    </FooterContents>
                </Footer>
            </Warapper>
        );
    }
}

export default withRouter(WrapMessageAPI(Login));