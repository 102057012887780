// StyledComponents.ts

import styled from 'styled-components';
import {Button, ColProps} from 'antd';
import { Col } from 'antd';

export const Container = styled.div`
    background-color: #f7f7f7;
    padding: 20px 13vw;
    min-height: 80vh;
    width: 100vw;
    border-radius: 8px;
`;

export const FormWrapper = styled.div`
    border-radius: 8px;
    margin: 0 150px;
    width: 100%;
    max-width: 1200px; /* 최대 너비를 더 넓게 */

    @media (max-width: 1024px) {
        margin: 0 60px; /* 중간 크기일 때 여백 조정 */
    }

    @media (max-width: 768px) {
        margin: 0 20px; /* 작은 화면일 때 여백 조정 */
        padding: 0 15px; /* 내부 패딩 추가 */
    }
`;

export const StyledColumn = styled(Col)<ColProps>`
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const DividerColumn = styled(Col)<ColProps>`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CustomButton = styled(Button)`
    font-size: 16px;
    padding: 7px 20px;
    width: 100%;
    //background-color: #7031be;
    margin-top: 20px;
`;

export const Footer = styled.div`
    font-size: 13px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
`;

export const NoteWrapper = styled.div`
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 4px;
    width: 100%;
    margin: 0 auto;
`;

