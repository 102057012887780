import {makeAutoObservable} from 'mobx';
import {apollo_client} from "../components/common/ApolloHelpper";
import {gql} from "@apollo/client";
import {EntityRequestInfo} from "../pages/admin/UserInfoPage";

class UserInfoStore {
    userInfoData: EntityRequestInfo[] = [];

    constructor() {
        makeAutoObservable(this);
        
    }

    async loadUserInfo(keyword: string = "") {
        try {
            const result = await apollo_client.query({
                query: gql`
                    query getReqInfos($keyword: String) {
                        getReqInfos(keyword: $keyword) {
                            id
                            koreanName
                            englishName
                            phone
                            email
                            position
                            company
                            introduction
                            createdAt
                            recommender
                            link {
                                id
                                name
                            }
                            profile {
                                id
                                name
                            }
                            career {
                                id
                                name
                            }
                            sns {
                                id
                                name
                            }
                            files {
                                id
                                category
                                downloadLink
                                fileName
                            }
                        }
                    }
                `,
                variables: {keyword},
                fetchPolicy: "no-cache"
            });

            if (result.data && result.data.getReqInfos) {
                this.userInfoData = result.data.getReqInfos.map((user: any) => ({
                    id: user.id,
                    koreanName: user.koreanName,
                    englishName: user.englishName,
                    phone: user.phone,
                    email: user.email,
                    position: user.position,
                    company: user.company,
                    introduction: user.introduction,
                    createdAt: user.createdAt,
                    recommender: user.recommender,
                    link: user.link?.map((link: any) => ({
                        id: link.id,
                        name: link.name
                    })) || [],
                    profile: user.profile?.map((profile: any) => ({
                        id: profile.id,
                        name: profile.name
                    })) || [],
                    career: user.career?.map((career: any) => ({
                        id: career.id,
                        name: career.name
                    })) || [],
                    sns: user.sns?.map((sns: any) => ({
                        id: sns.id,
                        name: sns.name
                    })) || [],
                    files: user.files?.map((file: any) => ({
                        id: file.id,
                        category: file.category,
                        downloadLink: file.downloadLink,
                        fileName: file.fileName
                    })) || []
                }));
                console.log('this.userInfoData', this.userInfoData);
            } else {
                console.error('Error: result.data.getReqInfos is undefined');
            }
        } catch (e) {
            console.error(e);
        }
    }
}

const 사용자정보Store = new UserInfoStore();

export {사용자정보Store};
