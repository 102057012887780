
import { gql } from "@apollo/client";
import {makeAutoObservable} from "mobx";
import {apollo_client_mediprofile} from "../components/common/ApolloHelpper";

class BlockchainStore {
    blockchainData: any[] = [];
    error: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    async loadBlockchainData() {
        try {
            const result = await apollo_client_mediprofile.query({
                query: gql`
                    query {
                        readBlockChainRows
                    }
                `,
                fetchPolicy: 'network-only', // 항상 네트워크에서 최신 데이터 가져오기
            });

            const rawData = result.data.readBlockChainRows;
            if (!rawData) {
                throw new Error('No data returned from the server.');
            }

            const parsedData = JSON.parse(rawData);
            if (!parsedData.chain || !Array.isArray(parsedData.chain)) {
                throw new Error('Invalid data structure');
            }

            const combinedData = parsedData.chain.flatMap((block: any) => {
                if (!block.transactions || block.transactions.length === 0) {
                    return [];
                }

                const transactionsByType1: Record<string, any> = {};
                block.transactions.forEach((transaction: any) => {
                    if (!transactionsByType1[transaction.type1]) {
                        transactionsByType1[transaction.type1] = {
                            id: transaction.type1,
                            block_number: block.index,
                            hash: block.previous_hash,
                            transactions: [],
                        };
                    }
                    transactionsByType1[transaction.type1].transactions.push(transaction.type2);
                });

                return Object.values(transactionsByType1);
            });

            this.blockchainData = combinedData.sort(
                (a: any, b: any) => b.block_number - a.block_number
            );

            console.log('Processed Data:', this.blockchainData);
            this.error = null;
        } catch (error:any) {
            console.error('Failed to load blockchain data:', error.message);
            this.error = 'Failed to load blockchain data';
        }
    }
}

export const blockchainStore = new BlockchainStore();