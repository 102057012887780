// PrivacyConsentComponent.tsx
import React, { useState } from "react";
import {Card, Checkbox, Typography} from "antd";
import styled from "styled-components";

const { Text, Paragraph } = Typography;

interface PrivacyConsentComponentProps {
    onConsentChange: (consent: boolean) => void; // 동의 체크박스의 상태를 상위 컴포넌트에 전달
}

const StyledCard = styled(Card)`
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border: none;
`;

const PrivacyConsentComponent: React.FC<PrivacyConsentComponentProps> = ({ onConsentChange }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (e: any) => {
        const checked = e.target.checked;
        setIsChecked(checked);
        onConsentChange(checked);
    };

    return (
        <StyledCard>
            <h3>개인정보 수집 및 이용에 대한 동의</h3>
            <Paragraph>
                <Text>
                    메디프로필 멤버십 등록을 위해 개인정보를 수집하고 있습니다.<br />
                    아래의 내용을 확인하시고 동의해 주세요.
                </Text>
            </Paragraph>
            <Paragraph>
                <Text strong>개인정보 수집 및 이용에 대한 안내</Text>
            </Paragraph>
            <Paragraph>
                <Text>1. 수집 및 이용의 목적: 메디프로필 제작</Text>
            </Paragraph>
            <Paragraph>
                <Text>
                    2. 수집하는 개인정보의 항목: 이름(한글, 영문), 휴대전화, 이메일, 소속, 경력 및 자기소개, 사진, 명함, 기타 자료(개인 자료, SNS, 동영상 등)
                </Text>
            </Paragraph>
            <Paragraph>
                <Text>3. 개인정보의 보유 및 이용 기간: 메디프로필 사용 종료 시</Text>
            </Paragraph>
            <Paragraph>
                <Text>
                    본 개인정보 수집 및 활용에 동의하지 않으시면 서비스 제공이 제한될 수 있습니다.
                </Text>
            </Paragraph>
            <Checkbox checked={isChecked} onChange={handleCheckboxChange}>
                개인정보 수집 및 이용에 동의합니다.
            </Checkbox>
        </StyledCard>
    );
};

export default PrivacyConsentComponent;