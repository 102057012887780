import { makeAutoObservable } from 'mobx';
import { apollo_client_mediprofile } from "../components/common/ApolloHelpper";
import { gql } from "@apollo/client";

class ProfileEventsStore {
    profileEventsData: any[] = []; // 데이터 타입을 명확히 지정할 수 있다면, 지정해주세요.
    loading = false;
    error: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    async loadProfileEvents() {
        this.loading = true;
        this.error = null;

        try {
            const result = await apollo_client_mediprofile.query({
                query: gql`
                    query getProfileEvents {
                        profile_events {
                            id
                            createdDate
                            uid
                            idNumber
                            eventType
                            eventDetail
                            name
                        }
                    }
                `,
                fetchPolicy: "no-cache"
            });

            // 데이터가 정상적으로 반환되었는지 확인
            if (result.data && result.data.profile_events) {
                // 반환된 데이터를 profileEventsData에 할당
                this.profileEventsData = result.data.profile_events;
                console.log('profileEventsData:', this.profileEventsData);
            } else {
                console.error('Error: result.data.profile_events is undefined');
            }
        } catch (e) {
            console.error(e);
            this.error = 'Failed to load profile events data';
        } finally {
            this.loading = false;
        }
    }
}

const 프로필이벤트Store = new ProfileEventsStore();

export { 프로필이벤트Store };