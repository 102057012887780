import React, { useEffect, useState } from "react";
import { ConfigProvider, Layout, Input, Button, Form, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import koKR from "antd/lib/locale/ko_KR";
import styled from "styled-components";
import imageCompression from "browser-image-compression"; // 이미지 압축 라이브러리

const FormWrapper = styled.div`
    max-width: 80vw;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FormRow = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
`;

const FormItemWrapper = styled(Form.Item)`
    flex: 1;
    min-width: 10vw;
`;

interface MakeVcfProps {
    defaultData?: {
        name?: string;
        company?: string;
        title?: string;
        phone?: string;
        email?: string;
        address?: string;
        url1?: string;
        url2?: string;
    };
}

const MakeVcf: React.FC<MakeVcfProps> = ({ defaultData }) => {

    useEffect(() => {
        // 새 창에서 sessionStorage로부터 데이터 불러오기
        const storedData = sessionStorage.getItem("vcfData");
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setFormData(parsedData);
        }
    }, []);

    const [formData, setFormData] = useState({
        name: defaultData?.name || "",
        company: defaultData?.company || "",
        title: defaultData?.title || "",
        phone: defaultData?.phone || "",
        email: defaultData?.email || "",
        address: defaultData?.address || "",
        url1: defaultData?.url1 || "",
        url2: defaultData?.url2 || "",
    });
    const [imageBase64, setImageBase64] = useState("");
    const [imagePreview, setImagePreview] = useState(""); // 미리보기 이미지 URL 상태

    useEffect(() => {
        if (defaultData) {
            setFormData((prev) => ({ ...prev, ...defaultData }));
        }
    }, [defaultData]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageUpload = async (file: any) => {
        const options = {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 500,
            useWebWorker: true,
        };

        try {
            const compressedFile = await imageCompression(file, options);
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Image = reader.result?.toString();
                setImageBase64(base64Image?.split(",")[1] || "");
                setImagePreview(base64Image || "");
            };
            reader.readAsDataURL(compressedFile);
        } catch (error) {
            message.error("이미지 압축에 실패했습니다. 100KB 이하의 이미지를 선택하세요.");
        }

        return false;
    };

    const generateVCard = () => {
        const { name, company, title, phone, email, address, url1, url2 } = formData;

        let vCardData = `BEGIN:VCARD\nVERSION:3.0\n`;

        const nField = `${name || ""} ${company || ""} ${title || ""}`.trim();
        vCardData += `N;CHARSET=UTF-8:${nField}\n`;
        vCardData += `TITLE;CHARSET=UTF-8:${title || ""}\n`;

        if (address) {
            vCardData += `ADR;CHARSET=UTF-8;TYPE=WORK:;;${address}\n`;
        }

        if (phone) {
            vCardData += `TEL;TYPE=CELL:${phone}\n`;
        }

        if (email) {
            vCardData += `EMAIL;TYPE=WORK:${email}\n`;
        }

        if (url1) {
            vCardData += `URL:${url1}\n`;
        }
        if (url2) {
            vCardData += `URL:${url2}\n`;
        }

        if (imageBase64) {
            vCardData += `PHOTO;TYPE=JPEG;ENCODING=BASE64:${imageBase64}\n`;
        }

        vCardData += `END:VCARD`;

        const blob = new Blob([vCardData], { type: "text/vcard;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${name}.vcf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        message.success("vCard 파일이 성공적으로 생성되었습니다.");
    };

    const renderInputField = (
        label: string,
        name: string,
        placeholder: string,
        example: string
    ) => (
        <FormItemWrapper label={label} extra={`ex). ${example}`}>
            <Input
                name={name}
                value={formData[name as keyof typeof formData]}
                onChange={handleInputChange}
                placeholder={placeholder}
            />
        </FormItemWrapper>
    );

    return (
        <ConfigProvider locale={koKR}>
            <Layout>
                <Layout.Content style={{ padding: "40px 0" }}>
                    <FormWrapper>
                        <h1>vCard 생성</h1>
                        <Form layout="vertical">
                            <FormRow>
                                <FormItemWrapper label="프로필 이미지">
                                    <Upload
                                        beforeUpload={handleImageUpload}
                                        accept="image/*"
                                        showUploadList={false}
                                    >
                                        <Button icon={<UploadOutlined />}>이미지 업로드</Button>
                                    </Upload>
                                    {imagePreview && (
                                        <img
                                            src={imagePreview}
                                            alt="미리보기"
                                            style={{ width: "100px", marginTop: "10px", borderRadius: "8px" }}
                                        />
                                    )}
                                </FormItemWrapper>
                                {renderInputField("이름", "name", "이름을 입력하세요", "임혜수")}
                                {renderInputField("회사", "company", "회사를 입력하세요", "(주)메디펀")}
                            </FormRow>
                            <FormRow>
                                {renderInputField("직책", "title", "직책을 입력하세요", "개발팀")}
                                {renderInputField("전화번호", "phone", "전화번호를 입력하세요", "010-1234-5678")}
                                {renderInputField("이메일", "email", "이메일을 입력하세요", "example@email.com")}
                            </FormRow>
                            <FormRow>
                                {renderInputField("주소", "address", "주소를 입력하세요", "부산광역시 남구 문현금융로 40")}
                                {renderInputField("URL 1", "url1", "URL 1을 입력하세요", "https://example.com")}
                                {renderInputField("URL 2", "url2", "URL 2를 입력하세요", "https://example2.com")}
                            </FormRow>
                            <Button type="primary" onClick={generateVCard}>
                                vCard 파일 생성
                            </Button>
                        </Form>
                    </FormWrapper>
                </Layout.Content>
            </Layout>
        </ConfigProvider>
    );
};

export default MakeVcf;