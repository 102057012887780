import TableComponent from "../../components/tables/TableComponent";
import {ProColumns} from "@ant-design/pro-components";
import {사용자정보Store} from "../../stores/UserInfoStore";
import {observer} from "mobx-react-lite";
import dayjs from "dayjs";
import koKR from "antd/lib/locale/ko_KR";
import React, {useEffect, useRef, useState} from "react";
import {ConfigProvider, Divider, Layout} from "antd";
import {FooterLayout} from "../../components/FooterLayout";
import styled from "styled-components";

const {Footer} = Layout;

export interface EntityRequestInfo {
    id: number;
    koreanName: string;
    englishName: string;
    phone: string;
    email: string;
    position: string;
    company: string;
    introduction: string;
    createdAt: string;
    link: {
        id: string;
        name: string;
    }[];
    profile: {
        id: string;
        name: string;
    }[];
    career: {
        id: string;
        name: string;
    }[];
    sns: {
        id: string;
        name: string;
    }[];
    files: {
        id: string;
        category: string;
        downloadLink: string;
        fileName: string;
    }[];
    recommender:string;
}

const FooterWrapper = styled.div`
    border-top: 1px solid #ccc;
    border-radius: 4px;
`;


const UserInfoPage: React.FC = observer(() => {
    const [dataSource, setDataSource] = useState<EntityRequestInfo[]>([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const rowRefs = useRef<Map<number, HTMLDivElement>>(new Map());

    useEffect(() => {
        const loadData = async () => {
            await 사용자정보Store.loadUserInfo(searchKeyword);
            setDataSource(사용자정보Store.userInfoData);
        };
        loadData();
    }, [searchKeyword]);

    const columns: ProColumns<EntityRequestInfo>[] = [
        {
            title: "순번",
            dataIndex: "id",
            align: "center",
            render: (_, __, index) => index + 1, // 순번 렌더링
            editable: false,
        },
        {
            title: "제출시간",
            dataIndex: "createdAt",
            align: "center",
            editable: false,
            render: (text) => dayjs(text as string).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
            title: "이름",
            dataIndex: "koreanName",
            align: "center",
            editable: false,
        },
        {
            title: "소속",
            dataIndex: "profile",
            align: "center",
            editable: false,
            render: (_, record) => (
                <div>
                    {record.profile.map((item, index) => (
                        <React.Fragment key={index}>
                            <div style={{padding: '4px 0'}}>{item.name}</div>
                            {index < record.profile.length - 1 && <Divider style={{margin: '4px 0'}}/>}
                        </React.Fragment>
                    ))}
                </div>
            ),
        },
        {
            title: "영문이름",
            dataIndex: "englishName",
            align: "center",
            editable: false,
        },
        {
            title: "전화번호",
            dataIndex: "phone",
            align: "center",
            editable: false,
        },
        {
            title: "이메일",
            dataIndex: "email",
            align: "center",
            editable: false,
        },
        {
            title: "추천인",
            dataIndex: "recommender",
            align: "center",
            editable: false,
        },
        {
            title: "상세보기",
            align: "center",
            render: (_, record) => (
                <a onClick={() => handleExpand(record.id)}>
                    {expandedRowKeys.includes(record.id) ? '닫기' : '상세보기'}
                </a>
            ),
            editable: false,
        },
    ];

    const handleSearch = (value: string) => {
        setSearchKeyword(value);
    };

    const handleExpand = (id: number) => {
        setExpandedRowKeys(prev => {
            const newExpandedRowKeys = prev.includes(id) ? [] : [id];
            if (!prev.includes(id)) {  // 상세보기를 처음 클릭했을 때
                setTimeout(() => {
                    const rowElement = rowRefs.current.get(id);
                    if (rowElement) {
                        rowElement.scrollIntoView({behavior: 'smooth', block: 'start'});
                    }
                }, 0);
            }
            return newExpandedRowKeys;
        });
    };

    return (
        <ConfigProvider locale={koKR}>
            <Layout>
                <TableComponent<EntityRequestInfo>
                    title=""
                    columns={columns}
                    dataSource={dataSource}
                    setDataSource={setDataSource as React.Dispatch<React.SetStateAction<EntityRequestInfo[]>>}
                    enableSearch={false}
                    showDateButtonGroup={false}
                    expandedRowKeys={expandedRowKeys}
                    setExpandedRowKeys={setExpandedRowKeys}
                    handleExpand={handleExpand}
                    rowRefs={rowRefs}
                    handleSearch={handleSearch}
                    setSearchKeyword={setSearchKeyword}
                    searchKeyword={searchKeyword}
                />
                <FooterWrapper>
                    <Footer style={{backgroundColor: 'white'}}><FooterLayout/></Footer>
                </FooterWrapper>
            </Layout>
        </ConfigProvider>
    );
});

export default UserInfoPage;
