import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from '../assets/logo.png';

const Nav = () => {
    return (
        <StyledNav>
            <DesktopMenu>
                <a href="https://www.mediprofile.info/" target="_blank" rel="noopener noreferrer">
                    <img src={logo} alt="메디펀 로고" width={180} height={60} />
                </a>
            </DesktopMenu>
            <NavButtonContainer>
                <NavButton>
                    <Link to={"/admin"}>관리자</Link>
                </NavButton>
                <NavButton>
                    <Link to={"/dashboard"}>대시보드</Link>
                </NavButton>
                <NavButton>
                    <Link to={"/dashboard-blockchain"}>블록체인</Link>
                </NavButton>
            </NavButtonContainer>
        </StyledNav>
    );
};

export default Nav;

// Styled-components
const StyledNav = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const DesktopMenu = styled.div`
    display: flex;
    align-items: center;

    a {
        text-decoration: none;
        color: #000;
    }

    img {
        margin-right: 20px;
    }
`;

const NavButtonContainer = styled.div`
    display: flex;
    gap: 10px;
`;


const NavButton = styled.div`
    background: linear-gradient(180deg, #8B8CD6 0%, #3581A7 100%);
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    a {
        text-decoration: none;
        color: #ffffff;
    }

    &:hover {
        background: linear-gradient(180deg, #6F76C5 0%, #2D7090 100%);
        cursor: pointer;
    }
`;