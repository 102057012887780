import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Card, Col, Layout, Row, Table, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { blockchainStore } from "../../stores/blockchain";

const { Title } = Typography;
const { Content } = Layout;

// Styled Components
const StyledCard = styled(Card)`
    margin-bottom: 16px;
    width: 100%;
`;

const StyledLayout = styled(Layout)`
    padding: 24px;
`;

const StyledRow = styled(Row)`
    margin-top: 16px;
`;

const BlockChainDashBoard: React.FC = observer(() => {
    const [dataSource, setDataSource] = useState<any[]>([]);

    useEffect(() => {
        const loadData = async () => {
            await blockchainStore.loadBlockchainData();
            setDataSource(blockchainStore.blockchainData); // MobX 상태를 직접 가져옴
        };

        // 즉시 한 번 로드
        loadData();

        // 1초마다 데이터 새로고침
        const interval = setInterval(() => {
            loadData();
        }, 1000);

        // 컴포넌트 언마운트 시 interval 제거
        return () => clearInterval(interval);
    }, []);


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: "15vw",
        },
        {
            title: "Block Number",
            dataIndex: "block_number",
            key: "block_number",
            width: "15vw",
        },
        {
            title: "Hash",
            dataIndex: "hash",
            key: "hash",
            width: "20vw",
            render: (text: string) => (
                <div
                    style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "20vw",
                    }}
                    title={text}
                >
                    {text}
                </div>
            ),
        },
        {
            title: "Transactions",
            dataIndex: "transactions",
            key: "transactions",
            width: "20vw",
            render: (text: string) => (
                <div
                    style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "20vw",
                    }}
                    title={text}
                >
                    {text}
                </div>
            ),
        },
    ];

    return (
        <StyledLayout>
            <Content>
                <Title level={2}>Blockchain Dashboard</Title>

                <StyledRow gutter={16}>
                    <Col span={24}>
                        <StyledCard title="Blockchain Data">
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                rowKey="id"
                                pagination={{ pageSize: 10 }}
                            />
                        </StyledCard>
                    </Col>
                </StyledRow>
            </Content>
        </StyledLayout>
    );
});

export default BlockChainDashBoard;