import React, {useState} from 'react';
import {Form, Input, Upload, Button, Row, Col, notification} from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { store } from '../../stores/RequestStore';

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const EssentialFormItems: React.FC<{ form: any }> = observer(({ form }) => {
    const [notifiedFiles, setNotifiedFiles] = useState<string[]>([]); // 이미 알림을 받은 파일 목록

    const isFileSizeValid = (file: any) => {
        return file.size <= MAX_FILE_SIZE;
    };


    // 파일 업로드 처리 함수
    const handleUploadChange = (info: any, fileListSetter: (fileList: any[]) => void) => {
        let fileList = [...info.fileList];
        let sizeExceeded = false; // 파일 크기 초과 여부 플래그

        // 파일 크기 확인 및 유효한 파일만 필터링
        fileList = fileList.map(file => {
            if (file.size && !isFileSizeValid(file)) {
                if (!notifiedFiles.includes(file.uid)) { // 이미 알림을 받은 파일이 아니면 알림 표시
                    sizeExceeded = true;
                    setNotifiedFiles([...notifiedFiles, file.uid]); // 알림을 받은 파일 ID 저장
                }
                return null; // 파일 크기가 유효하지 않은 경우 null로 처리
            }

            if (file.response) {
                file.status = 'done';
            }
            return file;
        }).filter(file => file !== null); // 유효한 파일만 유지

        // 파일 크기 초과 시 한 번만 알림 표시
        if (sizeExceeded) {
            notification.error({
                message: `파일 크기가 너무 큽니다. (최대 ${MAX_FILE_SIZE / (1024 * 1024)}MB까지 허용)`,
                placement: 'top',
                duration: 2,
            });
        }

        fileListSetter(fileList);
    };
    const normFile = (e: any) => {
        if (Array.isArray(e)) return e;
        return e?.fileList;
    };

    const validateFileList = (_: any, value: any) => {
        if (store.fileList사진자료.length < 3) {
            return Promise.reject(new Error('사진자료는 최소 3매 이상이어야 합니다.'));
        }
        return Promise.resolve();
    };

    return (
        <>
            {/* 성명과 영문 이름을 한 줄에 배치 */}
            <Row gutter={16}>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="koreanName"
                        label="성명"
                        rules={[{ required: true, message: '성명을 입력해주세요.' }]}
                    >
                        <Input placeholder="홍길동" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="englishName"
                        label="영문 이름"
                        rules={[{ required: true, message: '영문 이름을 입력해주세요.' }]}
                    >
                        <Input placeholder="HONG, GILDONG" />
                    </Form.Item>
                </Col>
            </Row>

            {/* 연락처와 이메일을 한 줄에 배치 */}
            <Row gutter={16}>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="phone"
                        label="연락처"
                        rules={[{ required: true, message: '휴대전화를 입력해주세요.' }]}
                    >
                        <Input placeholder="휴대전화" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="email"
                        label="이메일"
                        rules={[{ required: true, message: '이메일을 입력해주세요.' }]}
                    >
                        <Input placeholder="이메일" />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item
                label="경력 / 자기소개"
                required
                name={["career", 0]}
                rules={[{ required: true, message: '경력사항을 입력해주세요.' }]}
            >
                <Input placeholder="ex) OO대학 교수, 한국병원 외과 전문의" />
            </Form.Item>

            <Form.Item
                required
                name={["career", 1]}
                rules={[{ required: true, message: '자기소개를 입력해주세요.' }]}
            >
                <Input.TextArea
                    placeholder={"ex) 환자의 삶의 질 향상을 위해 끊임없이 연구하며, \n최신 의료 기술을 도입해 효과적인 치료를 제공하고자 노력하는 외과 전문의입니다.\n환자 중심의 진료를 최우선으로 생각합니다."}
                    rows={3}
                />
            </Form.Item>

            <Form.List name="careerDetails">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                required={true}
                                {...field}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[{ required: true, message: '경력사항을 입력해주세요.' }]}
                                noStyle
                            >
                                <Form.Item
                                    {...field}
                                    noStyle
                                >
                                    <Input placeholder="ex) 경영기획 전문가, 전)OO대학 겸임교수 등"
                                           style={{ width: 'calc(100% - 65px)' }} />
                                </Form.Item>
                                <Button
                                    type="link"
                                    onClick={() => remove(field.name)}
                                    style={{
                                        padding: 0,
                                        height: 'auto',
                                        lineHeight: '3',
                                        marginLeft: '8px',
                                        verticalAlign: 'middle'
                                    }}
                                >
                                    삭제
                                </Button>
                            </Form.Item>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: '100%' }}
                                icon={<PlusOutlined />}
                            >
                                경력 추가
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Form.Item
                label="사진자료"
                name="사진자료"
                required
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ validator: validateFileList }]}
                validateTrigger={['onChange', 'onBlur']}
            >
                <Upload
                    listType="picture"
                    maxCount={10}
                    onChange={(info) =>
                        handleUploadChange(info, (fileList) => store.setFileList사진자료(fileList)) // setter 메서드 호출
                    }
                >
                    <Button icon={<UploadOutlined />}>업로드</Button>
                </Upload>
                <div style={{ paddingTop: '5px', fontSize: '12px' }}>
                    ex) 프로필사진 포함 최소 3매이상 10매 미만
                </div>
            </Form.Item>
            {/* 명함 업로드 */}
            <Form.Item label="명함" valuePropName="fileList" getValueFromEvent={normFile}>
                <Upload
                    listType="picture"
                    maxCount={10}
                    onChange={(info) =>
                        handleUploadChange(info, (fileList) => store.setFileList명함(fileList)) // setter 메서드 호출
                    }
                >
                    <Button icon={<UploadOutlined />}>업로드</Button>
                </Upload>
                <div style={{ paddingTop: '5px', fontSize: '12px' }}>
                    ex) 앞면, 뒷면, 고화질 사진, PDF 등
                </div>
            </Form.Item>
        </>
    );
});

export default EssentialFormItems;